// Load htmx first using CommonJS require
import htmx from "htmx.org";
window.htmx = htmx;

// Then require the extensions
require("htmx-ext-debug");
require("htmx-ext-ws");

// Initialize hyperscript
const _hyperscript = require("hyperscript.org");
window._hyperscript = _hyperscript;
_hyperscript.browserInit();

// Uncomment below code snippet to enable htmx logging
// window.htmx.logger = function (elt, event, data) {
//   if (console) {
//     console.log(event, elt, data);
//   }
// };

document.addEventListener("htmx:afterRequest", function (event) {
  const hxRedirect = event.detail.xhr.getResponseHeader("hx-redirect");
  if (
    hxRedirect &&
    hxRedirect === window.location.pathname + window.location.hash
  ) {
    window.location.reload();
  }
});

document.addEventListener("DOMContentLoaded", function () {
  htmx.on("htmx:afterSettle", function (_event) {
    if (typeof initFlowbite === "function") {
      initFlowbite();
    }
  });
});
